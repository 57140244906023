
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Hotel',

    metaInfo: { title: 'About' },

    extends: View,

    mixins: [
      LoadSections([
        'automaticdishwashing',
        'kitchencleaner',
        'sanitarycleaner',
        'washingandcarpeting',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'hotel',
      },
    },
  }
</script>
